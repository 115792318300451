import React from 'react';


class CookieDrop extends React.Component {
    componentDidMount(){
        window.addEventListener('load', this.handleLoad)
    }

    handleLoad = () => {
        var response = {
            source : 'vlomni-customerui',
            payload : {
                accesstoken : localStorage.getItem('accesstoken')
            }
        }

        window.parent.postMessage(response, "*");
    }


    render() {
        return(
            <div>
            </div>
        )
    }
}

export default CookieDrop;
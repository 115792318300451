import {dropCookie} from './dropCookie';
import * as SS from './secureStorage'

export const resetStorage = () => {
    localStorage.removeItem("apiurl");
    localStorage.removeItem("bucketurl");
    localStorage.removeItem("accesstoken");
    localStorage.removeItem("updatetoken");
    localStorage.removeItem("refreshtoken");
    // localStorage.removeItem("customername");
    SS.secureStorage_removeItem('customername')
    SS.secureStorage_removeItem('secondary_customerids')
    SS.secureStorage_removeItem('selected_customername')
    SS.secureStorage_removeItem('accountstatus')
    SS.secureStorage_removeItem('access')

    SS.secureStorage_removeItem('PaymentGateway')
    SS.secureStorage_removeItem('PaymentGatewayID')
    SS.secureStorage_removeItem('CompanyID')
    SS.secureStorage_removeItem('EDIstatus')

    SS.secureStorage_removeItem('workflows')
    SS.secureStorage_removeItem('database')
    SS.secureStorage_removeItem('CustomerID')

    localStorage.removeItem("storeadminurl");
    localStorage.removeItem("username");
    localStorage.removeItem("expiresin");
    localStorage.removeItem('userAvatar');
    localStorage.removeItem('main_user_Avatar');
    
    

    dropCookie('vlomni-cookie-loggedin', '', 0)
    dropCookie('vlomni-cookie-active', '', 0)

    //clean up the local storage in case of they edited key value (this is optional)
    var exceptions = ['memoryuser']
    for (let i = 0; i < localStorage.length; i++){
        if (! exceptions.includes(localStorage.key(i))){
            localStorage.removeItem(localStorage.key(i))
        }
        
    }

}
/*
 * Copyright 2010-2016 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License").
 * You may not use this file except in compliance with the License.
 * A copy of the License is located at
 *
 *  http://aws.amazon.com/apache2.0
 *
 * or in the "license" file accompanying this file. This file is distributed
 * on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either
 * express or implied. See the License for the specific language governing
 * permissions and limitations under the License.
 */
import { APIGatewayClient, Utils, uritemplate } from 'aws-api-client'
import * as app_config from '../Config/config'

var apigClientFactory = {};
apigClientFactory.newClient = function (config, endpointURL) {
    var apigClient = {};
    if(config === undefined) {
        config = {
            accessKey: '',
            secretKey: '',
            sessionToken: '',
            region: '',
            apiKey: undefined,
            defaultContentType: 'application/json',
            defaultAcceptType: 'application/json'
        };
    }
    if(config.accessKey === undefined) {
        config.accessKey = '';
    }
    if(config.secretKey === undefined) {
        config.secretKey = '';
    }
    if(config.apiKey === undefined) {
        config.apiKey = '';
    }
    if(config.sessionToken === undefined) {
        config.sessionToken = '';
    }
    if(config.region === undefined) {
        config.region = 'us-east-1';
    }
    //If defaultContentType is not defined then default to application/json
    if(config.defaultContentType === undefined) {
        config.defaultContentType = 'application/json';
    }
    //If defaultAcceptType is not defined then default to application/json
    if(config.defaultAcceptType === undefined) {
        config.defaultAcceptType = 'application/json';
    }

    var invokeUrl = ""
    if (endpointURL === undefined){
        // extract endpoint and path from url
        invokeUrl = localStorage.getItem("apiurl");
        // if(!invokeUrl){
        //     if(localStorage.getItem("isProd") === 'TRUE'){
        //         invokeUrl = app_config.prodEndPoints['us-east-1'].apiurl
        //     }else if(localStorage.getItem("isProd") === 'FALSE'){
        //         invokeUrl = app_config.devEndPoints['us-east-1'].apiurl
        //     }
        //     else{
        //         invokeUrl = app_config.createConfig().isProd? app_config.prodEndPoints['us-east-1'].apiurl : app_config.devEndPoints['us-east-1'].apiurl
        //     }
        // }
        if (!invokeUrl) invokeUrl = app_config.createConfig().isProd? app_config.prodEndPoints['us-east-1'].apiurl : app_config.devEndPoints['us-east-1'].apiurl
    }else{
        invokeUrl = endpointURL;
    }


    // eslint-disable-next-line no-useless-escape
    var endpoint = /(^https?:\/\/[^\/]+)/g.exec(invokeUrl)[1];
    var pathComponent = invokeUrl.substring(endpoint.length);

    var sigV4ClientConfig = {
        accessKey: config.accessKey,
        secretKey: config.secretKey,
        sessionToken: config.sessionToken,
        serviceName: 'execute-api',
        region: config.region,
        endpoint: endpoint,
        defaultContentType: config.defaultContentType,
        defaultAcceptType: config.defaultAcceptType
    };

    var authType = 'NONE';
    if (sigV4ClientConfig.accessKey !== undefined && sigV4ClientConfig.accessKey !== '' && sigV4ClientConfig.secretKey !== undefined && sigV4ClientConfig.secretKey !== '') {
        authType = 'AWS_IAM';
    }

    var simpleHttpClientConfig = {
        endpoint: endpoint,
        defaultContentType: config.defaultContentType,
        defaultAcceptType: config.defaultAcceptType
    };

    var apiGatewayClient = new APIGatewayClient(simpleHttpClientConfig, sigV4ClientConfig);
    var utils = new Utils();
    
    
    apigClient.awsopsDownloadJsonFilePut = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        utils.assertParametersDefined(params, [], ['body']);
        
        var awsopsDownloadJsonFilePutRequest = {
            verb: 'put'.toUpperCase(),
            path: pathComponent + uritemplate('/awsops/downloadJsonFile').expand(utils.parseParametersToObject(params, [])),
            headers: utils.parseParametersToObject(params, []),
            queryParams: utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(awsopsDownloadJsonFilePutRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.awsopsDownloadJsonFileOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        utils.assertParametersDefined(params, [], ['body']);
        
        var awsopsDownloadJsonFileOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/awsops/downloadJsonFile').expand(utils.parseParametersToObject(params, [])),
            headers: utils.parseParametersToObject(params, []),
            queryParams: utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(awsopsDownloadJsonFileOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.awsopsDownloadTsvFilePut = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        utils.assertParametersDefined(params, [], ['body']);
        
        var awsopsDownloadTsvFilePutRequest = {
            verb: 'put'.toUpperCase(),
            path: pathComponent + uritemplate('/awsops/downloadTsvFile').expand(utils.parseParametersToObject(params, [])),
            headers: utils.parseParametersToObject(params, []),
            queryParams: utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(awsopsDownloadTsvFilePutRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.awsopsDownloadTsvFileOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        utils.assertParametersDefined(params, [], ['body']);
        
        var awsopsDownloadTsvFileOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/awsops/downloadTsvFile').expand(utils.parseParametersToObject(params, [])),
            headers: utils.parseParametersToObject(params, []),
            queryParams: utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(awsopsDownloadTsvFileOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.awsopsGetEmbeddedDashboardPut = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        utils.assertParametersDefined(params, [], ['body']);
        
        var awsopsGetEmbeddedDashboardPutRequest = {
            verb: 'put'.toUpperCase(),
            path: pathComponent + uritemplate('/awsops/getEmbeddedDashboard').expand(utils.parseParametersToObject(params, [])),
            headers: utils.parseParametersToObject(params, []),
            queryParams: utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(awsopsGetEmbeddedDashboardPutRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.awsopsGetEmbeddedDashboardOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        utils.assertParametersDefined(params, [], ['body']);
        
        var awsopsGetEmbeddedDashboardOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/awsops/getEmbeddedDashboard').expand(utils.parseParametersToObject(params, [])),
            headers: utils.parseParametersToObject(params, []),
            queryParams: utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(awsopsGetEmbeddedDashboardOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.awsopsPreSignedS3URLPut = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        utils.assertParametersDefined(params, [], ['body']);
        
        var awsopsPreSignedS3URLPutRequest = {
            verb: 'put'.toUpperCase(),
            path: pathComponent + uritemplate('/awsops/preSignedS3URL').expand(utils.parseParametersToObject(params, [])),
            headers: utils.parseParametersToObject(params, []),
            queryParams: utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(awsopsPreSignedS3URLPutRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.awsopsPreSignedS3URLOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        utils.assertParametersDefined(params, [], ['body']);
        
        var awsopsPreSignedS3URLOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/awsops/preSignedS3URL').expand(utils.parseParametersToObject(params, [])),
            headers: utils.parseParametersToObject(params, []),
            queryParams: utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(awsopsPreSignedS3URLOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.awsopsS3BucketFileListPut = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        utils.assertParametersDefined(params, [], ['body']);
        
        var awsopsS3BucketFileListPutRequest = {
            verb: 'put'.toUpperCase(),
            path: pathComponent + uritemplate('/awsops/s3BucketFileList').expand(utils.parseParametersToObject(params, [])),
            headers: utils.parseParametersToObject(params, []),
            queryParams: utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(awsopsS3BucketFileListPutRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.awsopsS3BucketFileListOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        utils.assertParametersDefined(params, [], ['body']);
        
        var awsopsS3BucketFileListOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/awsops/s3BucketFileList').expand(utils.parseParametersToObject(params, [])),
            headers: utils.parseParametersToObject(params, []),
            queryParams: utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(awsopsS3BucketFileListOptionsRequest, authType, additionalParams, config.apiKey);
    };
    

    apigClient.awsopsS3BucketFileList2Put = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        utils.assertParametersDefined(params, [], ['body']);
        
        var awsopsS3BucketFileList2PutRequest = {
            verb: 'put'.toUpperCase(),
            path: pathComponent + uritemplate('/awsops/s3BucketFileList2').expand(utils.parseParametersToObject(params, [])),
            headers: utils.parseParametersToObject(params, []),
            queryParams: utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(awsopsS3BucketFileList2PutRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.awsopsS3BucketFileList2Options = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        utils.assertParametersDefined(params, [], ['body']);
        
        var awsopsS3BucketFileList2OptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/awsops/s3BucketFileList2').expand(utils.parseParametersToObject(params, [])),
            headers: utils.parseParametersToObject(params, []),
            queryParams: utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(awsopsS3BucketFileList2OptionsRequest, authType, additionalParams, config.apiKey);
    };



    apigClient.awsopsS3BucketSearchFilePut = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        utils.assertParametersDefined(params, [], ['body']);
        
        var awsopsS3BucketSearchFilePutRequest = {
            verb: 'put'.toUpperCase(),
            path: pathComponent + uritemplate('/awsops/s3BucketSearchFile').expand(utils.parseParametersToObject(params, [])),
            headers: utils.parseParametersToObject(params, []),
            queryParams: utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(awsopsS3BucketSearchFilePutRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.awsopsS3BucketSearchFileOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        utils.assertParametersDefined(params, [], ['body']);
        
        var awsopsS3BucketSearchFileOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/awsops/s3BucketSearchFile').expand(utils.parseParametersToObject(params, [])),
            headers: utils.parseParametersToObject(params, []),
            queryParams: utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(awsopsS3BucketSearchFileOptionsRequest, authType, additionalParams, config.apiKey);
    };


    apigClient.awsopsS3BucketSubFoldersPut = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        utils.assertParametersDefined(params, [], ['body']);
        
        var awsopsS3BucketSubFoldersPutRequest = {
            verb: 'put'.toUpperCase(),
            path: pathComponent + uritemplate('/awsops/s3BucketSubFolders').expand(utils.parseParametersToObject(params, [])),
            headers: utils.parseParametersToObject(params, []),
            queryParams: utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(awsopsS3BucketSubFoldersPutRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.awsopsS3BucketSubFoldersOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        utils.assertParametersDefined(params, [], ['body']);
        
        var awsopsS3BucketSubFoldersOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/awsops/s3BucketSubFolders').expand(utils.parseParametersToObject(params, [])),
            headers: utils.parseParametersToObject(params, []),
            queryParams: utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(awsopsS3BucketSubFoldersOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.awsopsSearchSkuFromFilePut = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        utils.assertParametersDefined(params, [], ['body']);
        
        var awsopsSearchSkuFromFilePutRequest = {
            verb: 'put'.toUpperCase(),
            path: pathComponent + uritemplate('/awsops/searchSkuFromFile').expand(utils.parseParametersToObject(params, [])),
            headers: utils.parseParametersToObject(params, []),
            queryParams: utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(awsopsSearchSkuFromFilePutRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.awsopsSearchSkuFromFileOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        utils.assertParametersDefined(params, [], ['body']);
        
        var awsopsSearchSkuFromFileOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/awsops/searchSkuFromFile').expand(utils.parseParametersToObject(params, [])),
            headers: utils.parseParametersToObject(params, []),
            queryParams: utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(awsopsSearchSkuFromFileOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.awsopsSessionFilelistCustomernameWorkflownameSessionidGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        utils.assertParametersDefined(params, ['sessionid', 'workflowname', 'customername'], ['body']);
        
        var awsopsSessionFilelistCustomernameWorkflownameSessionidGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/awsops/sessionFilelist/{customername}/{workflowname}/{sessionid}').expand(utils.parseParametersToObject(params, ['sessionid', 'workflowname', 'customername'])),
            headers: utils.parseParametersToObject(params, []),
            queryParams: utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(awsopsSessionFilelistCustomernameWorkflownameSessionidGetRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.awsopsSessionFilelistCustomernameWorkflownameSessionidOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        utils.assertParametersDefined(params, [], ['body']);
        
        var awsopsSessionFilelistCustomernameWorkflownameSessionidOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/awsops/sessionFilelist/{customername}/{workflowname}/{sessionid}').expand(utils.parseParametersToObject(params, [])),
            headers: utils.parseParametersToObject(params, []),
            queryParams: utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(awsopsSessionFilelistCustomernameWorkflownameSessionidOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.configsCreateshopifywebhookPost = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        utils.assertParametersDefined(params, [], ['body']);
        
        var configsCreateshopifywebhookPostRequest = {
            verb: 'post'.toUpperCase(),
            path: pathComponent + uritemplate('/configs/createshopifywebhook').expand(utils.parseParametersToObject(params, [])),
            headers: utils.parseParametersToObject(params, []),
            queryParams: utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(configsCreateshopifywebhookPostRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.configsCreateshopifywebhookOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        utils.assertParametersDefined(params, [], ['body']);
        
        var configsCreateshopifywebhookOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/configs/createshopifywebhook').expand(utils.parseParametersToObject(params, [])),
            headers: utils.parseParametersToObject(params, []),
            queryParams: utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(configsCreateshopifywebhookOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.configsPopulateconfigPut = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        utils.assertParametersDefined(params, [], ['body']);
        
        var configsPopulateconfigPutRequest = {
            verb: 'put'.toUpperCase(),
            path: pathComponent + uritemplate('/configs/populateconfig').expand(utils.parseParametersToObject(params, [])),
            headers: utils.parseParametersToObject(params, []),
            queryParams: utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(configsPopulateconfigPutRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.configsPopulateconfigPost = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        utils.assertParametersDefined(params, [], ['body']);
        
        var configsPopulateconfigPostRequest = {
            verb: 'post'.toUpperCase(),
            path: pathComponent + uritemplate('/configs/populateconfig').expand(utils.parseParametersToObject(params, [])),
            headers: utils.parseParametersToObject(params, []),
            queryParams: utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(configsPopulateconfigPostRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.configsPopulateconfigOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        utils.assertParametersDefined(params, [], ['body']);
        
        var configsPopulateconfigOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/configs/populateconfig').expand(utils.parseParametersToObject(params, [])),
            headers: utils.parseParametersToObject(params, []),
            queryParams: utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(configsPopulateconfigOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.configsRetrieveWorkflowParamsPut = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        utils.assertParametersDefined(params, [], ['body']);
        
        var configsRetrieveWorkflowParamsPutRequest = {
            verb: 'put'.toUpperCase(),
            path: pathComponent + uritemplate('/configs/retrieveWorkflowParams').expand(utils.parseParametersToObject(params, [])),
            headers: utils.parseParametersToObject(params, []),
            queryParams: utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(configsRetrieveWorkflowParamsPutRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.configsRetrieveWorkflowParamsOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        utils.assertParametersDefined(params, [], ['body']);
        
        var configsRetrieveWorkflowParamsOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/configs/retrieveWorkflowParams').expand(utils.parseParametersToObject(params, [])),
            headers: utils.parseParametersToObject(params, []),
            queryParams: utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(configsRetrieveWorkflowParamsOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.configsSaveConnectorConfigPut = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        utils.assertParametersDefined(params, [], ['body']);
        
        var configsSaveConnectorConfigPutRequest = {
            verb: 'put'.toUpperCase(),
            path: pathComponent + uritemplate('/configs/saveConnectorConfig').expand(utils.parseParametersToObject(params, [])),
            headers: utils.parseParametersToObject(params, []),
            queryParams: utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(configsSaveConnectorConfigPutRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.configsSaveConnectorConfigOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        utils.assertParametersDefined(params, [], ['body']);
        
        var configsSaveConnectorConfigOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/configs/saveConnectorConfig').expand(utils.parseParametersToObject(params, [])),
            headers: utils.parseParametersToObject(params, []),
            queryParams: utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(configsSaveConnectorConfigOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.configsToggleIntegrationCWSCustomerIDWorkflowNamePut = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        utils.assertParametersDefined(params, ['CustomerID', 'WorkflowName'], ['body']);
        
        var configsToggleIntegrationCWSCustomerIDWorkflowNamePutRequest = {
            verb: 'put'.toUpperCase(),
            path: pathComponent + uritemplate('/configs/toggleIntegrationCWS/{CustomerID}/{WorkflowName}').expand(utils.parseParametersToObject(params, ['CustomerID', 'WorkflowName'])),
            headers: utils.parseParametersToObject(params, []),
            queryParams: utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(configsToggleIntegrationCWSCustomerIDWorkflowNamePutRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.configsToggleIntegrationCWSCustomerIDWorkflowNameOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        utils.assertParametersDefined(params, [], ['body']);
        
        var configsToggleIntegrationCWSCustomerIDWorkflowNameOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/configs/toggleIntegrationCWS/{CustomerID}/{WorkflowName}').expand(utils.parseParametersToObject(params, [])),
            headers: utils.parseParametersToObject(params, []),
            queryParams: utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(configsToggleIntegrationCWSCustomerIDWorkflowNameOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.dbopsDashboardUpdaterSessionidGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        utils.assertParametersDefined(params, ['sessionid'], ['body']);
        
        var dbopsDashboardUpdaterSessionidGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/dbops/dashboardUpdater/{sessionid}').expand(utils.parseParametersToObject(params, ['sessionid'])),
            headers: utils.parseParametersToObject(params, []),
            queryParams: utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(dbopsDashboardUpdaterSessionidGetRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.dbopsDashboardUpdaterSessionidOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        utils.assertParametersDefined(params, [], ['body']);
        
        var dbopsDashboardUpdaterSessionidOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/dbops/dashboardUpdater/{sessionid}').expand(utils.parseParametersToObject(params, [])),
            headers: utils.parseParametersToObject(params, []),
            queryParams: utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(dbopsDashboardUpdaterSessionidOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.dbopsGetLatestHeadersCustomernameGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        utils.assertParametersDefined(params, ['customername'], ['body']);
        
        var dbopsGetLatestHeadersCustomernameGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/dbops/getLatestHeaders/{customername}').expand(utils.parseParametersToObject(params, ['customername'])),
            headers: utils.parseParametersToObject(params, []),
            queryParams: utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(dbopsGetLatestHeadersCustomernameGetRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.dbopsGetLatestHeadersCustomernameOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        utils.assertParametersDefined(params, [], ['body']);
        
        var dbopsGetLatestHeadersCustomernameOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/dbops/getLatestHeaders/{customername}').expand(utils.parseParametersToObject(params, [])),
            headers: utils.parseParametersToObject(params, []),
            queryParams: utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(dbopsGetLatestHeadersCustomernameOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.dbopsGetLatestRecordsTablenameCustomernameGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        utils.assertParametersDefined(params, ['customername', 'tablename'], ['body']);
        
        var dbopsGetLatestRecordsTablenameCustomernameGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/dbops/getLatestRecords/{tablename}/{customername}').expand(utils.parseParametersToObject(params, ['customername', 'tablename'])),
            headers: utils.parseParametersToObject(params, []),
            queryParams: utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(dbopsGetLatestRecordsTablenameCustomernameGetRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.dbopsGetLatestRecordsTablenameCustomernameOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        utils.assertParametersDefined(params, [], ['body']);
        
        var dbopsGetLatestRecordsTablenameCustomernameOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/dbops/getLatestRecords/{tablename}/{customername}').expand(utils.parseParametersToObject(params, [])),
            headers: utils.parseParametersToObject(params, []),
            queryParams: utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(dbopsGetLatestRecordsTablenameCustomernameOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.dbopsGetOrderDetailsOrderheaderidGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        utils.assertParametersDefined(params, ['orderheaderid'], ['body']);
        
        var dbopsGetOrderDetailsOrderheaderidGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/dbops/getOrderDetails/{orderheaderid}').expand(utils.parseParametersToObject(params, ['orderheaderid'])),
            headers: utils.parseParametersToObject(params, []),
            queryParams: utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(dbopsGetOrderDetailsOrderheaderidGetRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.dbopsGetOrderDetailsOrderheaderidOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        utils.assertParametersDefined(params, [], ['body']);
        
        var dbopsGetOrderDetailsOrderheaderidOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/dbops/getOrderDetails/{orderheaderid}').expand(utils.parseParametersToObject(params, [])),
            headers: utils.parseParametersToObject(params, []),
            queryParams: utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(dbopsGetOrderDetailsOrderheaderidOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.dbopsGetTimelineDetailsConversationGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        utils.assertParametersDefined(params, ['conversation'], ['body']);
        
        var dbopsGetTimelineDetailsConversationGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/dbops/getTimelineDetails/{conversation}').expand(utils.parseParametersToObject(params, ['conversation'])),
            headers: utils.parseParametersToObject(params, []),
            queryParams: utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(dbopsGetTimelineDetailsConversationGetRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.dbopsGetTimelineDetailsConversationOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        utils.assertParametersDefined(params, [], ['body']);
        
        var dbopsGetTimelineDetailsConversationOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/dbops/getTimelineDetails/{conversation}').expand(utils.parseParametersToObject(params, [])),
            headers: utils.parseParametersToObject(params, []),
            queryParams: utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(dbopsGetTimelineDetailsConversationOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.dbopsInsertRecordsTablenamePost = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        utils.assertParametersDefined(params, ['tablename'], ['body']);
        
        var dbopsInsertRecordsTablenamePostRequest = {
            verb: 'post'.toUpperCase(),
            path: pathComponent + uritemplate('/dbops/insertRecords/{tablename}').expand(utils.parseParametersToObject(params, ['tablename'])),
            headers: utils.parseParametersToObject(params, []),
            queryParams: utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(dbopsInsertRecordsTablenamePostRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.dbopsInsertRecordsTablenameOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        utils.assertParametersDefined(params, [], ['body']);
        
        var dbopsInsertRecordsTablenameOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/dbops/insertRecords/{tablename}').expand(utils.parseParametersToObject(params, [])),
            headers: utils.parseParametersToObject(params, []),
            queryParams: utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(dbopsInsertRecordsTablenameOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.dbopsInsertwebservicemessagePost = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        utils.assertParametersDefined(params, [], ['body']);
        
        var dbopsInsertwebservicemessagePostRequest = {
            verb: 'post'.toUpperCase(),
            path: pathComponent + uritemplate('/dbops/insertwebservicemessage').expand(utils.parseParametersToObject(params, [])),
            headers: utils.parseParametersToObject(params, []),
            queryParams: utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(dbopsInsertwebservicemessagePostRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.dbopsInsertwebservicemessageOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        utils.assertParametersDefined(params, [], ['body']);
        
        var dbopsInsertwebservicemessageOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/dbops/insertwebservicemessage').expand(utils.parseParametersToObject(params, [])),
            headers: utils.parseParametersToObject(params, []),
            queryParams: utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(dbopsInsertwebservicemessageOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.dbopsNextCustomerIDCompanyNameGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        utils.assertParametersDefined(params, ['CompanyName'], ['body']);
        
        var dbopsNextCustomerIDCompanyNameGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/dbops/nextCustomerID/{CompanyName}').expand(utils.parseParametersToObject(params, ['CompanyName'])),
            headers: utils.parseParametersToObject(params, []),
            queryParams: utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(dbopsNextCustomerIDCompanyNameGetRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.dbopsNextCustomerIDCompanyNameOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        utils.assertParametersDefined(params, [], ['body']);
        
        var dbopsNextCustomerIDCompanyNameOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/dbops/nextCustomerID/{CompanyName}').expand(utils.parseParametersToObject(params, [])),
            headers: utils.parseParametersToObject(params, []),
            queryParams: utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(dbopsNextCustomerIDCompanyNameOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.dbopsProductActivitiesSearchbyskuCustomernameSkuGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        utils.assertParametersDefined(params, ['customername', 'sku'], ['body']);
        
        var dbopsProductActivitiesSearchbyskuCustomernameSkuGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/dbops/productActivities/searchbysku/{customername}/{sku}').expand(utils.parseParametersToObject(params, ['customername', 'sku'])),
            headers: utils.parseParametersToObject(params, []),
            queryParams: utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(dbopsProductActivitiesSearchbyskuCustomernameSkuGetRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.dbopsProductActivitiesSearchbyskuCustomernameSkuOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        utils.assertParametersDefined(params, [], ['body']);
        
        var dbopsProductActivitiesSearchbyskuCustomernameSkuOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/dbops/productActivities/searchbysku/{customername}/{sku}').expand(utils.parseParametersToObject(params, [])),
            headers: utils.parseParametersToObject(params, []),
            queryParams: utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(dbopsProductActivitiesSearchbyskuCustomernameSkuOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.dbopsProductActivitiesSessionDetailsCustomernameSessionidPut = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        utils.assertParametersDefined(params, ['sessionid', 'customername'], ['body']);
        
        var dbopsProductActivitiesSessionDetailsCustomernameSessionidPutRequest = {
            verb: 'put'.toUpperCase(),
            path: pathComponent + uritemplate('/dbops/productActivities/sessionDetails/{customername}/{sessionid}').expand(utils.parseParametersToObject(params, ['sessionid', 'customername'])),
            headers: utils.parseParametersToObject(params, []),
            queryParams: utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(dbopsProductActivitiesSessionDetailsCustomernameSessionidPutRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.dbopsProductActivitiesSessionDetailsCustomernameSessionidOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        utils.assertParametersDefined(params, [], ['body']);
        
        var dbopsProductActivitiesSessionDetailsCustomernameSessionidOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/dbops/productActivities/sessionDetails/{customername}/{sessionid}').expand(utils.parseParametersToObject(params, [])),
            headers: utils.parseParametersToObject(params, []),
            queryParams: utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(dbopsProductActivitiesSessionDetailsCustomernameSessionidOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.dbopsProductActivityCustomernamePut = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        utils.assertParametersDefined(params, ['customername'], ['body']);
        
        var dbopsProductActivityCustomernamePutRequest = {
            verb: 'put'.toUpperCase(),
            path: pathComponent + uritemplate('/dbops/productActivity/{customername}').expand(utils.parseParametersToObject(params, ['customername'])),
            headers: utils.parseParametersToObject(params, []),
            queryParams: utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(dbopsProductActivityCustomernamePutRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.dbopsProductActivityCustomernameOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        utils.assertParametersDefined(params, [], ['body']);
        
        var dbopsProductActivityCustomernameOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/dbops/productActivity/{customername}').expand(utils.parseParametersToObject(params, [])),
            headers: utils.parseParametersToObject(params, []),
            queryParams: utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(dbopsProductActivityCustomernameOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.dbopsPurgeIntegrationRecordsCustomerIDDelete = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        utils.assertParametersDefined(params, ['customerID'], ['body']);
        
        var dbopsPurgeIntegrationRecordsCustomerIDDeleteRequest = {
            verb: 'delete'.toUpperCase(),
            path: pathComponent + uritemplate('/dbops/purgeIntegrationRecords/{customerID}').expand(utils.parseParametersToObject(params, ['customerID'])),
            headers: utils.parseParametersToObject(params, []),
            queryParams: utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(dbopsPurgeIntegrationRecordsCustomerIDDeleteRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.dbopsPurgeIntegrationRecordsCustomerIDOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        utils.assertParametersDefined(params, [], ['body']);
        
        var dbopsPurgeIntegrationRecordsCustomerIDOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/dbops/purgeIntegrationRecords/{customerID}').expand(utils.parseParametersToObject(params, [])),
            headers: utils.parseParametersToObject(params, []),
            queryParams: utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(dbopsPurgeIntegrationRecordsCustomerIDOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.dbopsUpdateRecordsTablenameIDPut = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        utils.assertParametersDefined(params, ['tablename', 'ID'], ['body']);
        
        var dbopsUpdateRecordsTablenameIDPutRequest = {
            verb: 'put'.toUpperCase(),
            path: pathComponent + uritemplate('/dbops/updateRecords/{tablename}/{ID}').expand(utils.parseParametersToObject(params, ['tablename', 'ID'])),
            headers: utils.parseParametersToObject(params, []),
            queryParams: utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(dbopsUpdateRecordsTablenameIDPutRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.dbopsUpdateRecordsTablenameIDOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        utils.assertParametersDefined(params, [], ['body']);
        
        var dbopsUpdateRecordsTablenameIDOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/dbops/updateRecords/{tablename}/{ID}').expand(utils.parseParametersToObject(params, [])),
            headers: utils.parseParametersToObject(params, []),
            queryParams: utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(dbopsUpdateRecordsTablenameIDOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.dbopsUpdatewebservicemessagePut = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        utils.assertParametersDefined(params, [], ['body']);
        
        var dbopsUpdatewebservicemessagePutRequest = {
            verb: 'put'.toUpperCase(),
            path: pathComponent + uritemplate('/dbops/updatewebservicemessage').expand(utils.parseParametersToObject(params, [])),
            headers: utils.parseParametersToObject(params, []),
            queryParams: utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(dbopsUpdatewebservicemessagePutRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.dbopsUpdatewebservicemessageOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        utils.assertParametersDefined(params, [], ['body']);
        
        var dbopsUpdatewebservicemessageOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/dbops/updatewebservicemessage').expand(utils.parseParametersToObject(params, [])),
            headers: utils.parseParametersToObject(params, []),
            queryParams: utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(dbopsUpdatewebservicemessageOptionsRequest, authType, additionalParams, config.apiKey);
    };
    

    apigClient.dbopsBillableWorkflowsCustomernameGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        utils.assertParametersDefined(params, ['customername'], ['body']);
        
        var dbopsBillableWorkflowsCustomernameGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/dbops/billableWorkflows/{customername}').expand(utils.parseParametersToObject(params, ['customername'])),
            headers: utils.parseParametersToObject(params, []),
            queryParams: utils.parseParametersToObject(params, []),
            body: body
        };
        
        return apiGatewayClient.makeRequest(dbopsBillableWorkflowsCustomernameGetRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.dbopsBillableWorkflowsCustomernameOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        utils.assertParametersDefined(params, [], ['body']);
        
        var dbopsBillableWorkflowsCustomernameOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/dbops/billableWorkflows/{customername}').expand(utils.parseParametersToObject(params, [])),
            headers: utils.parseParametersToObject(params, []),
            queryParams: utils.parseParametersToObject(params, []),
            body: body
        };
        
        return apiGatewayClient.makeRequest(dbopsBillableWorkflowsCustomernameOptionsRequest, authType, additionalParams, config.apiKey);
    };



    apigClient.dbopsWorkflowActivityCustomernameGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        utils.assertParametersDefined(params, ['customername'], ['body']);
        
        var dbopsWorkflowActivityCustomernameGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/dbops/workflowActivity/{customername}').expand(utils.parseParametersToObject(params, ['customername'])),
            headers: utils.parseParametersToObject(params, []),
            queryParams: utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(dbopsWorkflowActivityCustomernameGetRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.dbopsWorkflowActivityCustomernameOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        utils.assertParametersDefined(params, [], ['body']);
        
        var dbopsWorkflowActivityCustomernameOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/dbops/workflowActivity/{customername}').expand(utils.parseParametersToObject(params, [])),
            headers: utils.parseParametersToObject(params, []),
            queryParams: utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(dbopsWorkflowActivityCustomernameOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.genericMakeRequestPut = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        utils.assertParametersDefined(params, [], ['body']);
        
        var genericMakeRequestPutRequest = {
            verb: 'put'.toUpperCase(),
            path: pathComponent + uritemplate('/generic/makeRequest').expand(utils.parseParametersToObject(params, [])),
            headers: utils.parseParametersToObject(params, []),
            queryParams: utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(genericMakeRequestPutRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.genericMakeRequestOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        utils.assertParametersDefined(params, [], ['body']);
        
        var genericMakeRequestOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/generic/makeRequest').expand(utils.parseParametersToObject(params, [])),
            headers: utils.parseParametersToObject(params, []),
            queryParams: utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(genericMakeRequestOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.gorgiasCheckIntegrationStatusCustomerIDIntegrationNameGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        utils.assertParametersDefined(params, ['integrationName', 'customerID'], ['body']);
        
        var gorgiasCheckIntegrationStatusCustomerIDIntegrationNameGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/gorgias/checkIntegrationStatus/{customerID}/{integrationName}').expand(utils.parseParametersToObject(params, ['integrationName', 'customerID'])),
            headers: utils.parseParametersToObject(params, []),
            queryParams: utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(gorgiasCheckIntegrationStatusCustomerIDIntegrationNameGetRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.gorgiasCheckIntegrationStatusCustomerIDIntegrationNameOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        utils.assertParametersDefined(params, [], ['body']);
        
        var gorgiasCheckIntegrationStatusCustomerIDIntegrationNameOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/gorgias/checkIntegrationStatus/{customerID}/{integrationName}').expand(utils.parseParametersToObject(params, [])),
            headers: utils.parseParametersToObject(params, []),
            queryParams: utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(gorgiasCheckIntegrationStatusCustomerIDIntegrationNameOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.gorgiasGetIntegrationParamsCustomerIDIntegrationNameGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        utils.assertParametersDefined(params, ['integrationName', 'customerID'], ['body']);
        
        var gorgiasGetIntegrationParamsCustomerIDIntegrationNameGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/gorgias/getIntegrationParams/{customerID}/{integrationName}').expand(utils.parseParametersToObject(params, ['integrationName', 'customerID'])),
            headers: utils.parseParametersToObject(params, []),
            queryParams: utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(gorgiasGetIntegrationParamsCustomerIDIntegrationNameGetRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.gorgiasGetIntegrationParamsCustomerIDIntegrationNameOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        utils.assertParametersDefined(params, [], ['body']);
        
        var gorgiasGetIntegrationParamsCustomerIDIntegrationNameOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/gorgias/getIntegrationParams/{customerID}/{integrationName}').expand(utils.parseParametersToObject(params, [])),
            headers: utils.parseParametersToObject(params, []),
            queryParams: utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(gorgiasGetIntegrationParamsCustomerIDIntegrationNameOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.gorgiasInstallAppWithCloningPost = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        utils.assertParametersDefined(params, [], ['body']);
        
        var gorgiasInstallAppWithCloningPostRequest = {
            verb: 'post'.toUpperCase(),
            path: pathComponent + uritemplate('/gorgias/installAppWithCloning').expand(utils.parseParametersToObject(params, [])),
            headers: utils.parseParametersToObject(params, []),
            queryParams: utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(gorgiasInstallAppWithCloningPostRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.gorgiasInstallAppWithCloningOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        utils.assertParametersDefined(params, [], ['body']);
        
        var gorgiasInstallAppWithCloningOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/gorgias/installAppWithCloning').expand(utils.parseParametersToObject(params, [])),
            headers: utils.parseParametersToObject(params, []),
            queryParams: utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(gorgiasInstallAppWithCloningOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.gorgiasOrderDetailsFullCustomernamePut = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        utils.assertParametersDefined(params, ['customername'], ['body']);
        
        var gorgiasOrderDetailsFullCustomernamePutRequest = {
            verb: 'put'.toUpperCase(),
            path: pathComponent + uritemplate('/gorgias/orderDetailsFull/{customername}').expand(utils.parseParametersToObject(params, ['customername'])),
            headers: utils.parseParametersToObject(params, []),
            queryParams: utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(gorgiasOrderDetailsFullCustomernamePutRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.gorgiasOrderDetailsFullCustomernameOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        utils.assertParametersDefined(params, [], ['body']);
        
        var gorgiasOrderDetailsFullCustomernameOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/gorgias/orderDetailsFull/{customername}').expand(utils.parseParametersToObject(params, [])),
            headers: utils.parseParametersToObject(params, []),
            queryParams: utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(gorgiasOrderDetailsFullCustomernameOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.lsomniMakeLSOMNIRequestPut = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        utils.assertParametersDefined(params, [], ['body']);
        
        var lsomniMakeLSOMNIRequestPutRequest = {
            verb: 'put'.toUpperCase(),
            path: pathComponent + uritemplate('/lsomni/makeLSOMNIRequest').expand(utils.parseParametersToObject(params, [])),
            headers: utils.parseParametersToObject(params, []),
            queryParams: utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(lsomniMakeLSOMNIRequestPutRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.lsomniMakeLSOMNIRequestOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        utils.assertParametersDefined(params, [], ['body']);
        
        var lsomniMakeLSOMNIRequestOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/lsomni/makeLSOMNIRequest').expand(utils.parseParametersToObject(params, [])),
            headers: utils.parseParametersToObject(params, []),
            queryParams: utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(lsomniMakeLSOMNIRequestOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.makeshopifyapicallbasicauthPut = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        utils.assertParametersDefined(params, [], ['body']);
        
        var makeshopifyapicallbasicauthPutRequest = {
            verb: 'put'.toUpperCase(),
            path: pathComponent + uritemplate('/makeshopifyapicallbasicauth').expand(utils.parseParametersToObject(params, [])),
            headers: utils.parseParametersToObject(params, []),
            queryParams: utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(makeshopifyapicallbasicauthPutRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.makeshopifyapicallbasicauthOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        utils.assertParametersDefined(params, [], ['body']);
        
        var makeshopifyapicallbasicauthOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/makeshopifyapicallbasicauth').expand(utils.parseParametersToObject(params, [])),
            headers: utils.parseParametersToObject(params, []),
            queryParams: utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(makeshopifyapicallbasicauthOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.makeshopifyapicallbasicauthAPIKeyPut = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        utils.assertParametersDefined(params, [], ['body']);
        
        var makeshopifyapicallbasicauthAPIKeyPutRequest = {
            verb: 'put'.toUpperCase(),
            path: pathComponent + uritemplate('/makeshopifyapicallbasicauthAPIKey').expand(utils.parseParametersToObject(params, [])),
            headers: utils.parseParametersToObject(params, []),
            queryParams: utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(makeshopifyapicallbasicauthAPIKeyPutRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.makeshopifyapicallbasicauthAPIKeyOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        utils.assertParametersDefined(params, [], ['body']);
        
        var makeshopifyapicallbasicauthAPIKeyOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/makeshopifyapicallbasicauthAPIKey').expand(utils.parseParametersToObject(params, [])),
            headers: utils.parseParametersToObject(params, []),
            queryParams: utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(makeshopifyapicallbasicauthAPIKeyOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.schedulerRunscheduleScheduleidGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        utils.assertParametersDefined(params, ['scheduleid'], ['body']);
        
        var schedulerRunscheduleScheduleidGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/scheduler/runschedule/{scheduleid}').expand(utils.parseParametersToObject(params, ['scheduleid'])),
            headers: utils.parseParametersToObject(params, []),
            queryParams: utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(schedulerRunscheduleScheduleidGetRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.schedulerRunscheduleScheduleidOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        utils.assertParametersDefined(params, [], ['body']);
        
        var schedulerRunscheduleScheduleidOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/scheduler/runschedule/{scheduleid}').expand(utils.parseParametersToObject(params, [])),
            headers: utils.parseParametersToObject(params, []),
            queryParams: utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(schedulerRunscheduleScheduleidOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.schedulerRunworkflownowCustomernameWorkflownameSchedulenameGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        utils.assertParametersDefined(params, ['workflowname', 'customername', 'schedulename'], ['body']);
        
        var schedulerRunworkflownowCustomernameWorkflownameSchedulenameGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/scheduler/runworkflownow/{customername}/{workflowname}/{schedulename}').expand(utils.parseParametersToObject(params, ['workflowname', 'customername', 'schedulename'])),
            headers: utils.parseParametersToObject(params, []),
            queryParams: utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(schedulerRunworkflownowCustomernameWorkflownameSchedulenameGetRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.schedulerRunworkflownowCustomernameWorkflownameSchedulenameOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        utils.assertParametersDefined(params, [], ['body']);
        
        var schedulerRunworkflownowCustomernameWorkflownameSchedulenameOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/scheduler/runworkflownow/{customername}/{workflowname}/{schedulename}').expand(utils.parseParametersToObject(params, [])),
            headers: utils.parseParametersToObject(params, []),
            queryParams: utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(schedulerRunworkflownowCustomernameWorkflownameSchedulenameOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.schedulerRunworkflownowidCustomeridkeyWorkflowidSchedulenameGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        utils.assertParametersDefined(params, ['customeridkey', 'workflowid', 'schedulename'], ['body']);
        
        var schedulerRunworkflownowidCustomeridkeyWorkflowidSchedulenameGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/scheduler/runworkflownowid/{customeridkey}/{workflowid}/{schedulename}').expand(utils.parseParametersToObject(params, ['customeridkey', 'workflowid', 'schedulename'])),
            headers: utils.parseParametersToObject(params, []),
            queryParams: utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(schedulerRunworkflownowidCustomeridkeyWorkflowidSchedulenameGetRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.schedulerRunworkflownowidCustomeridkeyWorkflowidSchedulenameOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        utils.assertParametersDefined(params, [], ['body']);
        
        var schedulerRunworkflownowidCustomeridkeyWorkflowidSchedulenameOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/scheduler/runworkflownowid/{customeridkey}/{workflowid}/{schedulename}').expand(utils.parseParametersToObject(params, [])),
            headers: utils.parseParametersToObject(params, []),
            queryParams: utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(schedulerRunworkflownowidCustomeridkeyWorkflowidSchedulenameOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.schedulerStopscheduleScheduleidGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        utils.assertParametersDefined(params, ['scheduleid'], ['body']);
        
        var schedulerStopscheduleScheduleidGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/scheduler/stopschedule/{scheduleid}').expand(utils.parseParametersToObject(params, ['scheduleid'])),
            headers: utils.parseParametersToObject(params, []),
            queryParams: utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(schedulerStopscheduleScheduleidGetRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.schedulerStopscheduleScheduleidOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        utils.assertParametersDefined(params, [], ['body']);
        
        var schedulerStopscheduleScheduleidOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/scheduler/stopschedule/{scheduleid}').expand(utils.parseParametersToObject(params, [])),
            headers: utils.parseParametersToObject(params, []),
            queryParams: utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(schedulerStopscheduleScheduleidOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.stripeAddCardToCustomerCustomerIDPut = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        utils.assertParametersDefined(params, ['CustomerID'], ['body']);
        
        var stripeAddCardToCustomerCustomerIDPutRequest = {
            verb: 'put'.toUpperCase(),
            path: pathComponent + uritemplate('/stripe/addCardToCustomer/{CustomerID}').expand(utils.parseParametersToObject(params, ['CustomerID'])),
            headers: utils.parseParametersToObject(params, []),
            queryParams: utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(stripeAddCardToCustomerCustomerIDPutRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.stripeAddCardToCustomerCustomerIDOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        utils.assertParametersDefined(params, [], ['body']);
        
        var stripeAddCardToCustomerCustomerIDOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/stripe/addCardToCustomer/{CustomerID}').expand(utils.parseParametersToObject(params, [])),
            headers: utils.parseParametersToObject(params, []),
            queryParams: utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(stripeAddCardToCustomerCustomerIDOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.stripeCustomerCustomerIDGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        utils.assertParametersDefined(params, ['CustomerID'], ['body']);
        
        var stripeCustomerCustomerIDGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/stripe/customer/{CustomerID}').expand(utils.parseParametersToObject(params, ['CustomerID'])),
            headers: utils.parseParametersToObject(params, []),
            queryParams: utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(stripeCustomerCustomerIDGetRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.stripeCustomerCustomerIDPost = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        utils.assertParametersDefined(params, ['CustomerID'], ['body']);
        
        var stripeCustomerCustomerIDPostRequest = {
            verb: 'post'.toUpperCase(),
            path: pathComponent + uritemplate('/stripe/customer/{CustomerID}').expand(utils.parseParametersToObject(params, ['CustomerID'])),
            headers: utils.parseParametersToObject(params, []),
            queryParams: utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(stripeCustomerCustomerIDPostRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.stripeCustomerCustomerIDOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        utils.assertParametersDefined(params, [], ['body']);
        
        var stripeCustomerCustomerIDOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/stripe/customer/{CustomerID}').expand(utils.parseParametersToObject(params, [])),
            headers: utils.parseParametersToObject(params, []),
            queryParams: utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(stripeCustomerCustomerIDOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.stripeMakeAPICallPost = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        utils.assertParametersDefined(params, [], ['body']);
        
        var stripeMakeAPICallPostRequest = {
            verb: 'post'.toUpperCase(),
            path: pathComponent + uritemplate('/stripe/makeAPICall').expand(utils.parseParametersToObject(params, [])),
            headers: utils.parseParametersToObject(params, []),
            queryParams: utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(stripeMakeAPICallPostRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.stripeMakeAPICallOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        utils.assertParametersDefined(params, [], ['body']);
        
        var stripeMakeAPICallOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/stripe/makeAPICall').expand(utils.parseParametersToObject(params, [])),
            headers: utils.parseParametersToObject(params, []),
            queryParams: utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(stripeMakeAPICallOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.vlauthCognitocustomattributesUsernameGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        utils.assertParametersDefined(params, ['username'], ['body']);
        
        var vlauthCognitocustomattributesUsernameGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/vlauth/cognitocustomattributes/{username}').expand(utils.parseParametersToObject(params, ['username'])),
            headers: utils.parseParametersToObject(params, []),
            queryParams: utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(vlauthCognitocustomattributesUsernameGetRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.vlauthCognitocustomattributesUsernamePut = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        utils.assertParametersDefined(params, ['username'], ['body']);
        
        var vlauthCognitocustomattributesUsernamePutRequest = {
            verb: 'put'.toUpperCase(),
            path: pathComponent + uritemplate('/vlauth/cognitocustomattributes/{username}').expand(utils.parseParametersToObject(params, ['username'])),
            headers: utils.parseParametersToObject(params, []),
            queryParams: utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(vlauthCognitocustomattributesUsernamePutRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.vlauthCognitocustomattributesUsernameOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        utils.assertParametersDefined(params, [], ['body']);
        
        var vlauthCognitocustomattributesUsernameOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/vlauth/cognitocustomattributes/{username}').expand(utils.parseParametersToObject(params, [])),
            headers: utils.parseParametersToObject(params, []),
            queryParams: utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(vlauthCognitocustomattributesUsernameOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.vlauthConfirmForgotPasswordPut = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        utils.assertParametersDefined(params, [], ['body']);
        
        var vlauthConfirmForgotPasswordPutRequest = {
            verb: 'put'.toUpperCase(),
            path: pathComponent + uritemplate('/vlauth/confirmForgotPassword').expand(utils.parseParametersToObject(params, [])),
            headers: utils.parseParametersToObject(params, []),
            queryParams: utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(vlauthConfirmForgotPasswordPutRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.vlauthConfirmForgotPasswordOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        utils.assertParametersDefined(params, [], ['body']);
        
        var vlauthConfirmForgotPasswordOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/vlauth/confirmForgotPassword').expand(utils.parseParametersToObject(params, [])),
            headers: utils.parseParametersToObject(params, []),
            queryParams: utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(vlauthConfirmForgotPasswordOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.vlauthConfirmUserPut = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        utils.assertParametersDefined(params, [], ['body']);
        
        var vlauthConfirmUserPutRequest = {
            verb: 'put'.toUpperCase(),
            path: pathComponent + uritemplate('/vlauth/confirmUser').expand(utils.parseParametersToObject(params, [])),
            headers: utils.parseParametersToObject(params, []),
            queryParams: utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(vlauthConfirmUserPutRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.vlauthConfirmUserOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        utils.assertParametersDefined(params, [], ['body']);
        
        var vlauthConfirmUserOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/vlauth/confirmUser').expand(utils.parseParametersToObject(params, [])),
            headers: utils.parseParametersToObject(params, []),
            queryParams: utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(vlauthConfirmUserOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.vlauthCustomerBillingInfoCustomerIDGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        utils.assertParametersDefined(params, ['CustomerID'], ['body']);
        
        var vlauthCustomerBillingInfoCustomerIDGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/vlauth/customerBillingInfo/{CustomerID}').expand(utils.parseParametersToObject(params, ['CustomerID'])),
            headers: utils.parseParametersToObject(params, []),
            queryParams: utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(vlauthCustomerBillingInfoCustomerIDGetRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.vlauthCustomerBillingInfoCustomerIDPut = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        utils.assertParametersDefined(params, ['CustomerID'], ['body']);
        
        var vlauthCustomerBillingInfoCustomerIDPutRequest = {
            verb: 'put'.toUpperCase(),
            path: pathComponent + uritemplate('/vlauth/customerBillingInfo/{CustomerID}').expand(utils.parseParametersToObject(params, ['CustomerID'])),
            headers: utils.parseParametersToObject(params, []),
            queryParams: utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(vlauthCustomerBillingInfoCustomerIDPutRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.vlauthCustomerBillingInfoCustomerIDOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        utils.assertParametersDefined(params, [], ['body']);
        
        var vlauthCustomerBillingInfoCustomerIDOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/vlauth/customerBillingInfo/{CustomerID}').expand(utils.parseParametersToObject(params, [])),
            headers: utils.parseParametersToObject(params, []),
            queryParams: utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(vlauthCustomerBillingInfoCustomerIDOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.vlauthForgotPasswordPut = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        utils.assertParametersDefined(params, [], ['body']);
        
        var vlauthForgotPasswordPutRequest = {
            verb: 'put'.toUpperCase(),
            path: pathComponent + uritemplate('/vlauth/forgotPassword').expand(utils.parseParametersToObject(params, [])),
            headers: utils.parseParametersToObject(params, []),
            queryParams: utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(vlauthForgotPasswordPutRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.vlauthForgotPasswordOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        utils.assertParametersDefined(params, [], ['body']);
        
        var vlauthForgotPasswordOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/vlauth/forgotPassword').expand(utils.parseParametersToObject(params, [])),
            headers: utils.parseParametersToObject(params, []),
            queryParams: utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(vlauthForgotPasswordOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.vlauthForgotUsernamePut = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        utils.assertParametersDefined(params, [], ['body']);
        
        var vlauthForgotUsernamePutRequest = {
            verb: 'put'.toUpperCase(),
            path: pathComponent + uritemplate('/vlauth/forgotUsername').expand(utils.parseParametersToObject(params, [])),
            headers: utils.parseParametersToObject(params, []),
            queryParams: utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(vlauthForgotUsernamePutRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.vlauthForgotUsernameOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        utils.assertParametersDefined(params, [], ['body']);
        
        var vlauthForgotUsernameOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/vlauth/forgotUsername').expand(utils.parseParametersToObject(params, [])),
            headers: utils.parseParametersToObject(params, []),
            queryParams: utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(vlauthForgotUsernameOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.vlauthIsUserConfirmedEmailGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        utils.assertParametersDefined(params, ['email'], ['body']);
        
        var vlauthIsUserConfirmedEmailGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/vlauth/isUserConfirmed/{email}').expand(utils.parseParametersToObject(params, ['email'])),
            headers: utils.parseParametersToObject(params, []),
            queryParams: utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(vlauthIsUserConfirmedEmailGetRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.vlauthIsUserConfirmedEmailOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        utils.assertParametersDefined(params, [], ['body']);
        
        var vlauthIsUserConfirmedEmailOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/vlauth/isUserConfirmed/{email}').expand(utils.parseParametersToObject(params, [])),
            headers: utils.parseParametersToObject(params, []),
            queryParams: utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(vlauthIsUserConfirmedEmailOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.vlauthResendConfirmationCodePut = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        utils.assertParametersDefined(params, [], ['body']);
        
        var vlauthResendConfirmationCodePutRequest = {
            verb: 'put'.toUpperCase(),
            path: pathComponent + uritemplate('/vlauth/resendConfirmationCode').expand(utils.parseParametersToObject(params, [])),
            headers: utils.parseParametersToObject(params, []),
            queryParams: utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(vlauthResendConfirmationCodePutRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.vlauthResendConfirmationCodeOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        utils.assertParametersDefined(params, [], ['body']);
        
        var vlauthResendConfirmationCodeOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/vlauth/resendConfirmationCode').expand(utils.parseParametersToObject(params, [])),
            headers: utils.parseParametersToObject(params, []),
            queryParams: utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(vlauthResendConfirmationCodeOptionsRequest, authType, additionalParams, config.apiKey);
    };

    
    apigClient.vlauthRefreshTokenPut = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        utils.assertParametersDefined(params, [], ['body']);
        
        var vlauthRefreshTokenPutRequest = {
            verb: 'put'.toUpperCase(),
            path: pathComponent + uritemplate('/vlauth/refreshToken').expand(utils.parseParametersToObject(params, [])),
            headers: utils.parseParametersToObject(params, []),
            queryParams: utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(vlauthRefreshTokenPutRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.vlauthRefreshTokenOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        utils.assertParametersDefined(params, [], ['body']);
        
        var vlauthRefreshTokenOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/vlauth/refreshToken').expand(utils.parseParametersToObject(params, [])),
            headers: utils.parseParametersToObject(params, []),
            queryParams: utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(vlauthRefreshTokenOptionsRequest, authType, additionalParams, config.apiKey);
    };


    
    apigClient.vlauthSigninUsernamePasswordPut = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        utils.assertParametersDefined(params, ['username', 'password'], ['body']);
        
        var vlauthSigninUsernamePasswordPutRequest = {
            verb: 'put'.toUpperCase(),
            path: pathComponent + uritemplate('/vlauth/signin/{username}/{password}').expand(utils.parseParametersToObject(params, ['username', 'password'])),
            headers: utils.parseParametersToObject(params, []),
            queryParams: utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(vlauthSigninUsernamePasswordPutRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.vlauthSigninUsernamePasswordOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        utils.assertParametersDefined(params, [], ['body']);
        
        var vlauthSigninUsernamePasswordOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/vlauth/signin/{username}/{password}').expand(utils.parseParametersToObject(params, [])),
            headers: utils.parseParametersToObject(params, []),
            queryParams: utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(vlauthSigninUsernamePasswordOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.vlauthSignupPost = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        utils.assertParametersDefined(params, [], ['body']);
        
        var vlauthSignupPostRequest = {
            verb: 'post'.toUpperCase(),
            path: pathComponent + uritemplate('/vlauth/signup').expand(utils.parseParametersToObject(params, [])),
            headers: utils.parseParametersToObject(params, []),
            queryParams: utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(vlauthSignupPostRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.vlauthSignupOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        utils.assertParametersDefined(params, [], ['body']);
        
        var vlauthSignupOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/vlauth/signup').expand(utils.parseParametersToObject(params, [])),
            headers: utils.parseParametersToObject(params, []),
            queryParams: utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(vlauthSignupOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.vlauthTempCredentialsPut = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        utils.assertParametersDefined(params, [], ['body']);
        
        var vlauthTempCredentialsPutRequest = {
            verb: 'put'.toUpperCase(),
            path: pathComponent + uritemplate('/vlauth/tempCredentials').expand(utils.parseParametersToObject(params, [])),
            headers: utils.parseParametersToObject(params, []),
            queryParams: utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(vlauthTempCredentialsPutRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.vlauthTempCredentialsOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        utils.assertParametersDefined(params, [], ['body']);
        
        var vlauthTempCredentialsOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/vlauth/tempCredentials').expand(utils.parseParametersToObject(params, [])),
            headers: utils.parseParametersToObject(params, []),
            queryParams: utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(vlauthTempCredentialsOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.vlauthTempCredentialsSTSCustomernameGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        utils.assertParametersDefined(params, ['customername'], ['body']);
        
        var vlauthTempCredentialsSTSCustomernameGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/vlauth/tempCredentialsSTS/{customername}').expand(utils.parseParametersToObject(params, ['customername'])),
            headers: utils.parseParametersToObject(params, []),
            queryParams: utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(vlauthTempCredentialsSTSCustomernameGetRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.vlauthTempCredentialsSTSCustomernameOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        utils.assertParametersDefined(params, [], ['body']);
        
        var vlauthTempCredentialsSTSCustomernameOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/vlauth/tempCredentialsSTS/{customername}').expand(utils.parseParametersToObject(params, [])),
            headers: utils.parseParametersToObject(params, []),
            queryParams: utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(vlauthTempCredentialsSTSCustomernameOptionsRequest, authType, additionalParams, config.apiKey);
    };
    


    apigClient.vlauthTempCredentialsSTSv2CustomernameGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        utils.assertParametersDefined(params, ['customername'], ['body']);
        
        var vlauthTempCredentialsSTSv2CustomernameGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/vlauth/tempCredentialsSTSv2/{customername}').expand(utils.parseParametersToObject(params, ['customername'])),
            headers: utils.parseParametersToObject(params, []),
            queryParams: utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(vlauthTempCredentialsSTSv2CustomernameGetRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.vlauthTempCredentialsSTSv2CustomernameOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        utils.assertParametersDefined(params, [], ['body']);
        
        var vlauthTempCredentialsSTSv2CustomernameOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/vlauth/tempCredentialsSTSv2/{customername}').expand(utils.parseParametersToObject(params, [])),
            headers: utils.parseParametersToObject(params, []),
            queryParams: utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(vlauthTempCredentialsSTSv2CustomernameOptionsRequest, authType, additionalParams, config.apiKey);
    };

    
    apigClient.vlauthUpdatePasswordPut = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        utils.assertParametersDefined(params, [], ['body']);
        
        var vlauthUpdatePasswordPutRequest = {
            verb: 'put'.toUpperCase(),
            path: pathComponent + uritemplate('/vlauth/updatePassword').expand(utils.parseParametersToObject(params, [])),
            headers: utils.parseParametersToObject(params, []),
            queryParams: utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(vlauthUpdatePasswordPutRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.vlauthUpdatePasswordOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        utils.assertParametersDefined(params, [], ['body']);
        
        var vlauthUpdatePasswordOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/vlauth/updatePassword').expand(utils.parseParametersToObject(params, [])),
            headers: utils.parseParametersToObject(params, []),
            queryParams: utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(vlauthUpdatePasswordOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.vlcredscheckValidateServiceCredsIntegrationNamePut = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        utils.assertParametersDefined(params, ['integrationName'], ['body']);
        
        var vlcredscheckValidateServiceCredsIntegrationNamePutRequest = {
            verb: 'put'.toUpperCase(),
            path: pathComponent + uritemplate('/vlcredscheck/validateServiceCreds/{integrationName}').expand(utils.parseParametersToObject(params, ['integrationName'])),
            headers: utils.parseParametersToObject(params, []),
            queryParams: utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(vlcredscheckValidateServiceCredsIntegrationNamePutRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.vlcredscheckValidateServiceCredsIntegrationNameOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        utils.assertParametersDefined(params, [], ['body']);
        
        var vlcredscheckValidateServiceCredsIntegrationNameOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/vlcredscheck/validateServiceCreds/{integrationName}').expand(utils.parseParametersToObject(params, [])),
            headers: utils.parseParametersToObject(params, []),
            queryParams: utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(vlcredscheckValidateServiceCredsIntegrationNameOptionsRequest, authType, additionalParams, config.apiKey);
    };

    apigClient.dbopsUploadFileToS3BucketPost = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        utils.assertParametersDefined(params, [], ['body']);
        
        var dbopsUploadFileToS3BucketPostRequest = {
            verb: 'post'.toUpperCase(),
            path: pathComponent + uritemplate('/dbops/uploadFileToS3Bucket').expand(utils.parseParametersToObject(params, [])),
            headers: utils.parseParametersToObject(params, []),
            queryParams: utils.parseParametersToObject(params, []),
            body: body
        };
        
        return apiGatewayClient.makeRequest(dbopsUploadFileToS3BucketPostRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.dbopsUploadFileToS3BucketOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        utils.assertParametersDefined(params, [], ['body']);
        
        var dbopsUploadFileToS3BucketOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/dbops/uploadFileToS3Bucket').expand(utils.parseParametersToObject(params, [])),
            headers: utils.parseParametersToObject(params, []),
            queryParams: utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(dbopsUploadFileToS3BucketOptionsRequest, authType, additionalParams, config.apiKey);
    };


    apigClient.dbopsDeleteFileFromS3BucketPost = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        utils.assertParametersDefined(params, [], ['body']);
        
        var dbopsDeleteFileFromS3BucketPostRequest = {
            verb: 'post'.toUpperCase(),
            path: pathComponent + uritemplate('/dbops/deleteFileFromS3Bucket').expand(utils.parseParametersToObject(params, [])),
            headers: utils.parseParametersToObject(params, []),
            queryParams: utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(dbopsDeleteFileFromS3BucketPostRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.dbopsDeleteFileFromS3BucketOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        utils.assertParametersDefined(params, [], ['body']);
        
        var dbopsDeleteFileFromS3BucketOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/dbops/deleteFileFromS3Bucket').expand(utils.parseParametersToObject(params, [])),
            headers: utils.parseParametersToObject(params, []),
            queryParams: utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(dbopsDeleteFileFromS3BucketOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.dbopsEmptyFolderInS3BucketPost = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        utils.assertParametersDefined(params, [], ['body']);
        
        var dbopsEmptyFolderInS3BucketPostRequest = {
            verb: 'post'.toUpperCase(),
            path: pathComponent + uritemplate('/dbops/emptyFolderInS3Bucket').expand(utils.parseParametersToObject(params, [])),
            headers: utils.parseParametersToObject(params, []),
            queryParams: utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(dbopsEmptyFolderInS3BucketPostRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.dbopsEmptyFolderInS3BucketOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        utils.assertParametersDefined(params, [], ['body']);
        
        var dbopsEmptyFolderInS3BucketOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/dbops/emptyFolderInS3Bucket').expand(utils.parseParametersToObject(params, [])),
            headers: utils.parseParametersToObject(params, []),
            queryParams: utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(dbopsEmptyFolderInS3BucketOptionsRequest, authType, additionalParams, config.apiKey);
    };


    apigClient.awsopsGetCUSTCUIConstantParameterGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        utils.assertParametersDefined(params, ['parameter'], ['body']);
        
        var awsopsGetCUSTCUIConstantParameterGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/awsops/getCUSTCUIConstant/{parameter}').expand(utils.parseParametersToObject(params, ['parameter'])),
            headers: utils.parseParametersToObject(params, []),
            queryParams: utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(awsopsGetCUSTCUIConstantParameterGetRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.awsopsGetCUSTCUIConstantParameterOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        utils.assertParametersDefined(params, [], ['body']);
        
        var awsopsGetCUSTCUIConstantParameterOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/awsops/getCUSTCUIConstant/{parameter}').expand(utils.parseParametersToObject(params, [])),
            headers: utils.parseParametersToObject(params, []),
            queryParams: utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(awsopsGetCUSTCUIConstantParameterOptionsRequest, authType, additionalParams, config.apiKey);
    };



    apigClient.dbopsGetCrossReferencesCustomernameGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        utils.assertParametersDefined(params, ['customername'], ['body']);
        
        var dbopsGetCrossReferencesCustomernameGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/dbops/getCrossReferences/{customername}').expand(utils.parseParametersToObject(params, ['customername'])),
            headers: utils.parseParametersToObject(params, []),
            queryParams: utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(dbopsGetCrossReferencesCustomernameGetRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.dbopsGetCrossReferencesCustomernameOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        utils.assertParametersDefined(params, [], ['body']);
        
        var dbopsGetCrossReferencesCustomernameOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/dbops/getCrossReferences/{customername}').expand(utils.parseParametersToObject(params, [])),
            headers: utils.parseParametersToObject(params, []),
            queryParams: utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(dbopsGetCrossReferencesCustomernameOptionsRequest, authType, additionalParams, config.apiKey);
    };


    apigClient.awsopsCompressFilesPut = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        utils.assertParametersDefined(params, [], ['body']);
        
        var awsopsCompressFilesPutRequest = {
            verb: 'put'.toUpperCase(),
            path: pathComponent + uritemplate('/awsops/compressFiles').expand(utils.parseParametersToObject(params, [])),
            headers: utils.parseParametersToObject(params, []),
            queryParams: utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(awsopsCompressFilesPutRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.awsopsCompressFilesOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        utils.assertParametersDefined(params, [], ['body']);
        
        var awsopsCompressFilesOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/awsops/compressFiles').expand(utils.parseParametersToObject(params, [])),
            headers: utils.parseParametersToObject(params, []),
            queryParams: utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(awsopsCompressFilesOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    apigClient.awsopsGenerateLogPut = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        utils.assertParametersDefined(params, [], ['body']);
        
        var awsopsGenerateLogPutRequest = {
            verb: 'put'.toUpperCase(),
            path: pathComponent + uritemplate('/awsops/generateLog').expand(utils.parseParametersToObject(params, [])),
            headers: utils.parseParametersToObject(params, []),
            queryParams: utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(awsopsGenerateLogPutRequest, authType, additionalParams, config.apiKey);
    };
    
    //check api call
    apigClient.awsopsGenerateLogOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        utils.assertParametersDefined(params, [], ['body']);
        
        var awsopsGenerateLogOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/awsops/generateLog').expand(utils.parseParametersToObject(params, [])),
            headers: utils.parseParametersToObject(params, []),
            queryParams: utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(awsopsGenerateLogOptionsRequest, authType, additionalParams, config.apiKey);
    };
    //check api call
    apigClient.vlconfigsRetrievelocalparamsCustomernameWorkflownameGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        utils.assertParametersDefined(params, ['workflowname', 'customername'], ['body']);
        
        var vlconfigsRetrievelocalparamsCustomernameWorkflownameGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/vlconfigs/retrievelocalparams/{customername}/{workflowname}').expand(utils.parseParametersToObject(params, ['workflowname', 'customername'])),
            headers: utils.parseParametersToObject(params, []),
            queryParams: utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(vlconfigsRetrievelocalparamsCustomernameWorkflownameGetRequest, authType, additionalParams, config.apiKey);
    };
    
    //check api call
    apigClient.vlconfigsRetrievelocalparamsCustomernameWorkflownameOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        utils.assertParametersDefined(params, [], ['body']);
        
        var vlconfigsRetrievelocalparamsCustomernameWorkflownameOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/vlconfigs/retrievelocalparams/{customername}/{workflowname}').expand(utils.parseParametersToObject(params, [])),
            headers: utils.parseParametersToObject(params, []),
            queryParams: utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(vlconfigsRetrievelocalparamsCustomernameWorkflownameOptionsRequest, authType, additionalParams, config.apiKey);
    };
    

    apigClient.configsUpdatecrossreferencePut = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        utils.assertParametersDefined(params, [], ['body']);
        
        var configsUpdatecrossreferencePutRequest = {
            verb: 'put'.toUpperCase(),
            path: pathComponent + uritemplate('/configs/updatecrossreference').expand(utils.parseParametersToObject(params, [])),
            headers: utils.parseParametersToObject(params, []),
            queryParams: utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(configsUpdatecrossreferencePutRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.configsUpdatecrossreferenceOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        utils.assertParametersDefined(params, [], ['body']);
        
        var configsUpdatecrossreferenceOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/configs/updatecrossreference').expand(utils.parseParametersToObject(params, [])),
            headers: utils.parseParametersToObject(params, []),
            queryParams: utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(configsUpdatecrossreferenceOptionsRequest, authType, additionalParams, config.apiKey);
    };



    apigClient.dbopsGetLatestTransactionsCustomernameGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        utils.assertParametersDefined(params, ['customername'], ['body']);
        
        var dbopsGetLatestTransactionsCustomernameGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/dbops/getLatestTransactions/{customername}').expand(utils.parseParametersToObject(params, ['customername'])),
            headers: utils.parseParametersToObject(params, []),
            queryParams: utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(dbopsGetLatestTransactionsCustomernameGetRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.dbopsGetLatestTransactionsCustomernameOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        utils.assertParametersDefined(params, [], ['body']);
        
        var dbopsGetLatestTransactionsCustomernameOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/dbops/getLatestTransactions/{customername}').expand(utils.parseParametersToObject(params, [])),
            headers: utils.parseParametersToObject(params, []),
            queryParams: utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(dbopsGetLatestTransactionsCustomernameOptionsRequest, authType, additionalParams, config.apiKey);
    };
    


    apigClient.dbopsUpdateTicketsPut = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        utils.assertParametersDefined(params, [], ['body']);
        
        var dbopsUpdateTicketsPutRequest = {
            verb: 'put'.toUpperCase(),
            path: pathComponent + uritemplate('/dbops/updateTickets').expand(utils.parseParametersToObject(params, [])),
            headers: utils.parseParametersToObject(params, []),
            queryParams: utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(dbopsUpdateTicketsPutRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.dbopsUpdateTicketsOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        utils.assertParametersDefined(params, [], ['body']);
        
        var dbopsUpdateTicketsOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/dbops/updateTickets').expand(utils.parseParametersToObject(params, [])),
            headers: utils.parseParametersToObject(params, []),
            queryParams: utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(dbopsUpdateTicketsOptionsRequest, authType, additionalParams, config.apiKey);
    };
    

    apigClient.dbopsBulkupdatewebservicemessagePut = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        utils.assertParametersDefined(params, [], ['body']);
        
        var dbopsBulkupdatewebservicemessagePutRequest = {
            verb: 'put'.toUpperCase(),
            path: pathComponent + uritemplate('/dbops/bulkupdatewebservicemessage').expand(utils.parseParametersToObject(params, [])),
            headers: utils.parseParametersToObject(params, []),
            queryParams: utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(dbopsBulkupdatewebservicemessagePutRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.dbopsBulkupdatewebservicemessageOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        utils.assertParametersDefined(params, [], ['body']);
        
        var dbopsBulkupdatewebservicemessageOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/dbops/bulkupdatewebservicemessage').expand(utils.parseParametersToObject(params, [])),
            headers: utils.parseParametersToObject(params, []),
            queryParams: utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(dbopsBulkupdatewebservicemessageOptionsRequest, authType, additionalParams, config.apiKey);
    };


    apigClient.stripeUpdateCustomerCustomerIDPut = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        utils.assertParametersDefined(params, ['CustomerID'], ['body']);
        
        var stripeUpdateCustomerCustomerIDPutRequest = {
            verb: 'put'.toUpperCase(),
            path: pathComponent + uritemplate('/stripe/updateCustomer/{CustomerID}').expand(utils.parseParametersToObject(params, ['CustomerID'])),
            headers: utils.parseParametersToObject(params, []),
            queryParams: utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(stripeUpdateCustomerCustomerIDPutRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.stripeUpdateCustomerCustomerIDOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        utils.assertParametersDefined(params, [], ['body']);
        
        var stripeUpdateCustomerCustomerIDOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/stripe/updateCustomer/{CustomerID}').expand(utils.parseParametersToObject(params, [])),
            headers: utils.parseParametersToObject(params, []),
            queryParams: utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(stripeUpdateCustomerCustomerIDOptionsRequest, authType, additionalParams, config.apiKey);
    };
    

    apigClient.dbopsGetLatestRecordsV2TablenameCustomernameGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        utils.assertParametersDefined(params, ['customername', 'tablename'], ['body']);
        
        var dbopsGetLatestRecordsV2TablenameCustomernameGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/dbops/getLatestRecordsV2/{tablename}/{customername}').expand(utils.parseParametersToObject(params, ['customername', 'tablename'])),
            headers: utils.parseParametersToObject(params, []),
            queryParams: utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(dbopsGetLatestRecordsV2TablenameCustomernameGetRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.dbopsGetLatestRecordsV2TablenameCustomernameOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        utils.assertParametersDefined(params, [], ['body']);
        
        var dbopsGetLatestRecordsV2TablenameCustomernameOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/dbops/getLatestRecordsV2/{tablename}/{customername}').expand(utils.parseParametersToObject(params, [])),
            headers: utils.parseParametersToObject(params, []),
            queryParams: utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(dbopsGetLatestRecordsV2TablenameCustomernameOptionsRequest, authType, additionalParams, config.apiKey);
    };


    apigClient.stripeMakePaymentCustomerIDPut = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        utils.assertParametersDefined(params, ['CustomerID'], ['body']);
        
        var stripeMakePaymentCustomerIDPutRequest = {
            verb: 'put'.toUpperCase(),
            path: pathComponent + uritemplate('/stripe/makePayment/{CustomerID}').expand(utils.parseParametersToObject(params, ['CustomerID'])),
            headers: utils.parseParametersToObject(params, []),
            queryParams: utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(stripeMakePaymentCustomerIDPutRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.stripeMakePaymentCustomerIDOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        utils.assertParametersDefined(params, [], ['body']);
        
        var stripeMakePaymentCustomerIDOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/stripe/makePayment/{CustomerID}').expand(utils.parseParametersToObject(params, [])),
            headers: utils.parseParametersToObject(params, []),
            queryParams: utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(stripeMakePaymentCustomerIDOptionsRequest, authType, additionalParams, config.apiKey);
    };


    apigClient.awsopsGetPDFGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        utils.assertParametersDefined(params, [], ['body']);
        
        var awsopsGetPDFGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/awsops/getPDF').expand(utils.parseParametersToObject(params, [])),
            headers: utils.parseParametersToObject(params, []),
            queryParams: utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(awsopsGetPDFGetRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.awsopsGetPDFOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        utils.assertParametersDefined(params, [], ['body']);
        
        var awsopsGetPDFOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/awsops/getPDF').expand(utils.parseParametersToObject(params, [])),
            headers: utils.parseParametersToObject(params, []),
            queryParams: utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(awsopsGetPDFOptionsRequest, authType, additionalParams, config.apiKey);
    };


    apigClient.awsopsS3BucketFileList3Put = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        utils.assertParametersDefined(params, [], ['body']);
        
        var awsopsS3BucketFileList3PutRequest = {
            verb: 'put'.toUpperCase(),
            path: pathComponent + uritemplate('/awsops/s3BucketFileList3').expand(utils.parseParametersToObject(params, [])),
            headers: utils.parseParametersToObject(params, []),
            queryParams: utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(awsopsS3BucketFileList3PutRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.awsopsS3BucketFileList3Options = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        utils.assertParametersDefined(params, [], ['body']);
        
        var awsopsS3BucketFileList3OptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/awsops/s3BucketFileList3').expand(utils.parseParametersToObject(params, [])),
            headers: utils.parseParametersToObject(params, []),
            queryParams: utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(awsopsS3BucketFileList3OptionsRequest, authType, additionalParams, config.apiKey);
    };


    apigClient.dbopsGetEnvironmentGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        utils.assertParametersDefined(params, [], ['body']);
        
        var dbopsGetEnvironmentGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/dbops/getEnvironment').expand(utils.parseParametersToObject(params, [])),
            headers: utils.parseParametersToObject(params, []),
            queryParams: utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(dbopsGetEnvironmentGetRequest, authType, additionalParams, config.apiKey);
    };

    apigClient.dbopsGetRegionGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        utils.assertParametersDefined(params, [], ['body']);
        
        var dbopsGetRegionGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/dbops/getRegion').expand(utils.parseParametersToObject(params, [])),
            headers: utils.parseParametersToObject(params, []),
            queryParams: utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(dbopsGetRegionGetRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.dbopsGetRegionOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        utils.assertParametersDefined(params, [], ['body']);
        
        var dbopsGetRegionOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/dbops/getRegion').expand(utils.parseParametersToObject(params, [])),
            headers: utils.parseParametersToObject(params, []),
            queryParams: utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(dbopsGetRegionOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    

    apigClient.stripeMakeAPICallV2Post = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        utils.assertParametersDefined(params, [], ['body']);
        
        var stripeMakeAPICallV2PostRequest = {
            verb: 'post'.toUpperCase(),
            path: pathComponent + uritemplate('/stripe/makeAPICallV2').expand(utils.parseParametersToObject(params, [])),
            headers: utils.parseParametersToObject(params, []),
            queryParams: utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(stripeMakeAPICallV2PostRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.stripeMakeAPICallV2Options = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        utils.assertParametersDefined(params, [], ['body']);
        
        var stripeMakeAPICallV2OptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/stripe/makeAPICallV2').expand(utils.parseParametersToObject(params, [])),
            headers: utils.parseParametersToObject(params, []),
            queryParams: utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(stripeMakeAPICallV2OptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    

    apigClient.stripeCustomerV2CustomerIDGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        utils.assertParametersDefined(params, ['CustomerID'], ['body']);
        
        var stripeCustomerV2CustomerIDGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/stripe/customerV2/{CustomerID}').expand(utils.parseParametersToObject(params, ['CustomerID'])),
            headers: utils.parseParametersToObject(params, []),
            queryParams: utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(stripeCustomerV2CustomerIDGetRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.stripeCustomerV2CustomerIDPost = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        utils.assertParametersDefined(params, ['CustomerID'], ['body']);
        
        var stripeCustomerV2CustomerIDPostRequest = {
            verb: 'post'.toUpperCase(),
            path: pathComponent + uritemplate('/stripe/customerV2/{CustomerID}').expand(utils.parseParametersToObject(params, ['CustomerID'])),
            headers: utils.parseParametersToObject(params, []),
            queryParams: utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(stripeCustomerV2CustomerIDPostRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.stripeCustomerV2CustomerIDOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        utils.assertParametersDefined(params, [], ['body']);
        
        var stripeCustomerV2CustomerIDOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/stripe/customerV2/{CustomerID}').expand(utils.parseParametersToObject(params, [])),
            headers: utils.parseParametersToObject(params, []),
            queryParams: utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(stripeCustomerV2CustomerIDOptionsRequest, authType, additionalParams, config.apiKey);
    };


    apigClient.stripeMakePaymentV2CustomerIDPut = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        utils.assertParametersDefined(params, ['CustomerID'], ['body']);
        
        var stripeMakePaymentV2CustomerIDPutRequest = {
            verb: 'put'.toUpperCase(),
            path: pathComponent + uritemplate('/stripe/makePaymentV2/{CustomerID}').expand(utils.parseParametersToObject(params, ['CustomerID'])),
            headers: utils.parseParametersToObject(params, []),
            queryParams: utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(stripeMakePaymentV2CustomerIDPutRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.stripeMakePaymentV2CustomerIDOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        utils.assertParametersDefined(params, [], ['body']);
        
        var stripeMakePaymentV2CustomerIDOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/stripe/makePaymentV2/{CustomerID}').expand(utils.parseParametersToObject(params, [])),
            headers: utils.parseParametersToObject(params, []),
            queryParams: utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(stripeMakePaymentV2CustomerIDOptionsRequest, authType, additionalParams, config.apiKey);
    };

    apigClient.stripeUpdateCustomerV2CustomerIDPut = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        utils.assertParametersDefined(params, ['CustomerID'], ['body']);
        
        var stripeUpdateCustomerV2CustomerIDPutRequest = {
            verb: 'put'.toUpperCase(),
            path: pathComponent + uritemplate('/stripe/updateCustomerV2/{CustomerID}').expand(utils.parseParametersToObject(params, ['CustomerID'])),
            headers: utils.parseParametersToObject(params, []),
            queryParams: utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(stripeUpdateCustomerV2CustomerIDPutRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.stripeUpdateCustomerV2CustomerIDOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        utils.assertParametersDefined(params, [], ['body']);
        
        var stripeUpdateCustomerV2CustomerIDOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/stripe/updateCustomerV2/{CustomerID}').expand(utils.parseParametersToObject(params, [])),
            headers: utils.parseParametersToObject(params, []),
            queryParams: utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(stripeUpdateCustomerV2CustomerIDOptionsRequest, authType, additionalParams, config.apiKey);
    };
    
    apigClient.vlauthSigninV2UsernamePasswordPut = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        utils.assertParametersDefined(params, ['username', 'password'], ['body']);
        
        var vlauthSigninV2UsernamePasswordPutRequest = {
            verb: 'put'.toUpperCase(),
            path: pathComponent + uritemplate('/vlauth/signinV2/{username}/{password}').expand(utils.parseParametersToObject(params, ['username', 'password'])),
            headers: utils.parseParametersToObject(params, []),
            queryParams: utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(vlauthSigninV2UsernamePasswordPutRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.vlauthSigninV2UsernamePasswordOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        utils.assertParametersDefined(params, [], ['body']);
        
        var vlauthSigninV2UsernamePasswordOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/vlauth/signinV2/{username}/{password}').expand(utils.parseParametersToObject(params, [])),
            headers: utils.parseParametersToObject(params, []),
            queryParams: utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(vlauthSigninV2UsernamePasswordOptionsRequest, authType, additionalParams, config.apiKey);
    };

    apigClient.dbopsInsertLogCustomerIDPost = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        utils.assertParametersDefined(params, ['CustomerID'], ['body']);
        
        var dbopsInsertLogCustomerIDPostRequest = {
            verb: 'post'.toUpperCase(),
            path: pathComponent + uritemplate('/dbops/insertLog/{CustomerID}').expand(utils.parseParametersToObject(params, ['CustomerID'])),
            headers: utils.parseParametersToObject(params, []),
            queryParams: utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(dbopsInsertLogCustomerIDPostRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.dbopsInsertLogCustomerIDOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        utils.assertParametersDefined(params, [], ['body']);
        
        var dbopsInsertLogCustomerIDOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/dbops/insertLog/{CustomerID}').expand(utils.parseParametersToObject(params, [])),
            headers: utils.parseParametersToObject(params, []),
            queryParams: utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(dbopsInsertLogCustomerIDOptionsRequest, authType, additionalParams, config.apiKey);
    };    


    apigClient.cognitoGetRegionCgGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        utils.assertParametersDefined(params, [], ['body']);
        
        var cognitoGetRegionCgGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/cognito/getRegionCg').expand(utils.parseParametersToObject(params, [])),
            headers: utils.parseParametersToObject(params, []),
            queryParams: utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(cognitoGetRegionCgGetRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.cognitoGetRegionCgOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        utils.assertParametersDefined(params, [], ['body']);
        
        var cognitoGetRegionCgOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/cognito/getRegionCg').expand(utils.parseParametersToObject(params, [])),
            headers: utils.parseParametersToObject(params, []),
            queryParams: utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(cognitoGetRegionCgOptionsRequest, authType, additionalParams, config.apiKey);
    };

    apigClient.vlauthCheckUsernameUsernameGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        utils.assertParametersDefined(params, ['username'], ['body']);
        
        var vlauthCheckUsernameUsernameGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/vlauth/checkUsername/{username}').expand(utils.parseParametersToObject(params, ['username'])),
            headers: utils.parseParametersToObject(params, []),
            queryParams: utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(vlauthCheckUsernameUsernameGetRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.vlauthCheckUsernameUsernameOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        utils.assertParametersDefined(params, [], ['body']);
        
        var vlauthCheckUsernameUsernameOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/vlauth/checkUsername/{username}').expand(utils.parseParametersToObject(params, [])),
            headers: utils.parseParametersToObject(params, []),
            queryParams: utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(vlauthCheckUsernameUsernameOptionsRequest, authType, additionalParams, config.apiKey);
    };
    

    apigClient.dbopsWorkflowActivityV2CustomernameGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        utils.assertParametersDefined(params, ['customername'], ['body']);
        
        var dbopsWorkflowActivityV2CustomernameGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/dbops/workflowActivityV2/{customername}').expand(utils.parseParametersToObject(params, ['customername'])),
            headers: utils.parseParametersToObject(params, []),
            queryParams: utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(dbopsWorkflowActivityV2CustomernameGetRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.dbopsWorkflowActivityV2CustomernameOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        utils.assertParametersDefined(params, [], ['body']);
        
        var dbopsWorkflowActivityV2CustomernameOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/dbops/workflowActivityV2/{customername}').expand(utils.parseParametersToObject(params, [])),
            headers: utils.parseParametersToObject(params, []),
            queryParams: utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(dbopsWorkflowActivityV2CustomernameOptionsRequest, authType, additionalParams, config.apiKey);
    };



    apigClient.dbopsBillableWorkflowsV2CustomernameGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        utils.assertParametersDefined(params, ['customername'], ['body']);
        
        var dbopsBillableWorkflowsV2CustomernameGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/dbops/billableWorkflowsV2/{customername}').expand(utils.parseParametersToObject(params, ['customername'])),
            headers: utils.parseParametersToObject(params, []),
            queryParams: utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(dbopsBillableWorkflowsV2CustomernameGetRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.dbopsBillableWorkflowsV2CustomernameOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        utils.assertParametersDefined(params, [], ['body']);
        
        var dbopsBillableWorkflowsV2CustomernameOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/dbops/billableWorkflowsV2/{customername}').expand(utils.parseParametersToObject(params, [])),
            headers: utils.parseParametersToObject(params, []),
            queryParams: utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(dbopsBillableWorkflowsV2CustomernameOptionsRequest, authType, additionalParams, config.apiKey);
    };


    apigClient.dbopsGetUserInfoCustomernameUsernameGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        utils.assertParametersDefined(params, ['username', 'customername'], ['body']);
        
        var dbopsGetUserInfoCustomernameUsernameGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/dbops/getUserInfo/{customername}/{username}').expand(utils.parseParametersToObject(params, ['username', 'customername'])),
            headers: utils.parseParametersToObject(params, []),
            queryParams: utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(dbopsGetUserInfoCustomernameUsernameGetRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.dbopsGetUserInfoCustomernameUsernameOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        utils.assertParametersDefined(params, [], ['body']);
        
        var dbopsGetUserInfoCustomernameUsernameOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/dbops/getUserInfo/{customername}/{username}').expand(utils.parseParametersToObject(params, [])),
            headers: utils.parseParametersToObject(params, []),
            queryParams: utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(dbopsGetUserInfoCustomernameUsernameOptionsRequest, authType, additionalParams, config.apiKey);
    };

    apigClient.dbopsGetChannelImagesGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        utils.assertParametersDefined(params, [], ['body']);
        
        var dbopsGetChannelImagesGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/dbops/getChannelImages').expand(utils.parseParametersToObject(params, [])),
            headers: utils.parseParametersToObject(params, []),
            queryParams: utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(dbopsGetChannelImagesGetRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.dbopsGetChannelImagesOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        utils.assertParametersDefined(params, [], ['body']);
        
        var dbopsGetChannelImagesOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/dbops/getChannelImages').expand(utils.parseParametersToObject(params, [])),
            headers: utils.parseParametersToObject(params, []),
            queryParams: utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(dbopsGetChannelImagesOptionsRequest, authType, additionalParams, config.apiKey);
    };
    



    apigClient.dbopsBillableWorkflowsV2CustomernameGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        utils.assertParametersDefined(params, ['customername'], ['body']);
        
        var dbopsBillableWorkflowsV2CustomernameGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/dbops/billableWorkflowsV2/{customername}').expand(utils.parseParametersToObject(params, ['customername'])),
            headers: utils.parseParametersToObject(params, []),
            queryParams: utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(dbopsBillableWorkflowsV2CustomernameGetRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.dbopsBillableWorkflowsV2CustomernameOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        utils.assertParametersDefined(params, [], ['body']);
        
        var dbopsBillableWorkflowsV2CustomernameOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/dbops/billableWorkflowsV2/{customername}').expand(utils.parseParametersToObject(params, [])),
            headers: utils.parseParametersToObject(params, []),
            queryParams: utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(dbopsBillableWorkflowsV2CustomernameOptionsRequest, authType, additionalParams, config.apiKey);
    };



    apigClient.dbopsWorkflowActivityV2CustomernameGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        utils.assertParametersDefined(params, ['customername'], ['body']);
        
        var dbopsWorkflowActivityV2CustomernameGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/dbops/workflowActivityV2/{customername}').expand(utils.parseParametersToObject(params, ['customername'])),
            headers: utils.parseParametersToObject(params, []),
            queryParams: utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(dbopsWorkflowActivityV2CustomernameGetRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.dbopsWorkflowActivityV2CustomernameOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        utils.assertParametersDefined(params, [], ['body']);
        
        var dbopsWorkflowActivityV2CustomernameOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/dbops/workflowActivityV2/{customername}').expand(utils.parseParametersToObject(params, [])),
            headers: utils.parseParametersToObject(params, []),
            queryParams: utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(dbopsWorkflowActivityV2CustomernameOptionsRequest, authType, additionalParams, config.apiKey);
    };
    


    apigClient.dbopsGetOrderDetailsPokeyPokeyGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        utils.assertParametersDefined(params, ['pokey'], ['body']);
        
        var dbopsGetOrderDetailsPokeyPokeyGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/dbops/getOrderDetailsPokey/{pokey}').expand(utils.parseParametersToObject(params, ['pokey'])),
            headers: utils.parseParametersToObject(params, []),
            queryParams: utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(dbopsGetOrderDetailsPokeyPokeyGetRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.dbopsGetOrderDetailsPokeyPokeyOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        utils.assertParametersDefined(params, [], ['body']);
        
        var dbopsGetOrderDetailsPokeyPokeyOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/dbops/getOrderDetailsPokey/{pokey}').expand(utils.parseParametersToObject(params, [])),
            headers: utils.parseParametersToObject(params, []),
            queryParams: utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(dbopsGetOrderDetailsPokeyPokeyOptionsRequest, authType, additionalParams, config.apiKey);
    };



    apigClient.dbopsGetTimelineDetailsWithIDCustomeridIdGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        utils.assertParametersDefined(params, ['customerid', 'id'], ['body']);
        
        var dbopsGetTimelineDetailsWithIDCustomeridIdGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/dbops/getTimelineDetailsWithID/{customerid}/{id}').expand(utils.parseParametersToObject(params, ['customerid', 'id'])),
            headers: utils.parseParametersToObject(params, []),
            queryParams: utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(dbopsGetTimelineDetailsWithIDCustomeridIdGetRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.dbopsGetTimelineDetailsWithIDCustomeridIdOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        utils.assertParametersDefined(params, [], ['body']);
        
        var dbopsGetTimelineDetailsWithIDCustomeridIdOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/dbops/getTimelineDetailsWithID/{customerid}/{id}').expand(utils.parseParametersToObject(params, [])),
            headers: utils.parseParametersToObject(params, []),
            queryParams: utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(dbopsGetTimelineDetailsWithIDCustomeridIdOptionsRequest, authType, additionalParams, config.apiKey);
    };


    apigClient.dbopsGetLatestTransactionsV2CustomernameGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        utils.assertParametersDefined(params, ['customername'], ['body']);
        
        var dbopsGetLatestTransactionsV2CustomernameGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/dbops/getLatestTransactionsV2/{customername}').expand(utils.parseParametersToObject(params, ['customername'])),
            headers: utils.parseParametersToObject(params, []),
            queryParams: utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(dbopsGetLatestTransactionsV2CustomernameGetRequest, authType, additionalParams, config.apiKey);
    };
    
    
    apigClient.dbopsGetLatestTransactionsV2CustomernameOptions = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        utils.assertParametersDefined(params, [], ['body']);
        
        var dbopsGetLatestTransactionsV2CustomernameOptionsRequest = {
            verb: 'options'.toUpperCase(),
            path: pathComponent + uritemplate('/dbops/getLatestTransactionsV2/{customername}').expand(utils.parseParametersToObject(params, [])),
            headers: utils.parseParametersToObject(params, []),
            queryParams: utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(dbopsGetLatestTransactionsV2CustomernameOptionsRequest, authType, additionalParams, config.apiKey);
    };
      
    apigClient.replaySessionWorkflowGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        utils.assertParametersDefined(params, [], ['body']);
        
        var replaySessionWorkflowGet = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/scheduler/replayworkflownowid/{sflogID}/{executionID}/{custID}/{sessionID}/{workflowID}/{stepfnID}/{stepfnInput}').expand(utils.parseParametersToObject(params,  ['sflogID','executionID','custID','sessionID','workflowID','stepfnID', 'stepfnInput'])),
            headers: utils.parseParametersToObject(params, []),
            queryParams: utils.parseParametersToObject(params, []),
            body: body
        };
        
        return apiGatewayClient.makeRequest(replaySessionWorkflowGet, authType, additionalParams, config.apiKey);
    };

    //Amazon App calls - need to Test
    apigClient.getLWARefreshTokenPOST = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        utils.assertParametersDefined(params);
        
        var dbopsInsertLogCustomerIDPostRequest = {
            verb: 'post'.toUpperCase(),
            path: pathComponent + uritemplate('/awsops/tempCredentialsSTS}').expand(utils.parseParametersToObject(params, ['CustomerID'])),
            headers: utils.parseParametersToObject(params, []),
            queryParams: utils.parseParametersToObject(params, []),
            body: body
        };
        
        
        return apiGatewayClient.makeRequest(dbopsInsertLogCustomerIDPostRequest, authType, additionalParams, config.apiKey);
    };
    //get temp sts creds for amz token
    apigClient.awsopsTempCredentialsSTSGet = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        utils.assertParametersDefined(params, [], ['body']);

        var awsopsTempCredentialsSTSGetRequest = {
            verb: 'get'.toUpperCase(),
            path: pathComponent + uritemplate('/awsops/tempCredentialsSTS').expand(utils.parseParametersToObject(params, [])),
            headers: utils.parseParametersToObject(params, []),
            queryParams: utils.parseParametersToObject(params, []),
            body: body
        };
        console.log(awsopsTempCredentialsSTSGetRequest)
        return apiGatewayClient.makeRequest(awsopsTempCredentialsSTSGetRequest, authType, additionalParams, config.apiKey);
    };
    apigClient.dbopsInsertAmazonMWS_CredsPost = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        utils.assertParametersDefined(params, [], ['body']);
        
        var dbopsInsertAmazonMWS_CredsPostRequest = {
            verb: 'post'.toUpperCase(),
            path: pathComponent + uritemplate('/dbops/insertAmazonMWS_Creds').expand(utils.parseParametersToObject(params, [])),
            headers: utils.parseParametersToObject(params, []),
            queryParams: utils.parseParametersToObject(params, []),
            body: body
        };
        
        return apiGatewayClient.makeRequest(dbopsInsertAmazonMWS_CredsPostRequest, authType, additionalParams, config.apiKey);
    };

    apigClient.restageOrderPost = function (params, body, additionalParams) {
        if(additionalParams === undefined) { additionalParams = {}; }
        
        utils.assertParametersDefined(params, [], ['body']);
        var dbopsRestageOrderPostRequest = {
            verb: 'post'.toUpperCase(),
            path: pathComponent + uritemplate('/reconviacustui/{customername}').expand(utils.parseParametersToObject(params, ['customername'])),
            headers: utils.parseParametersToObject(params, []),
            queryParams: utils.parseParametersToObject(params, []),
            body: body
        };
        
        return apiGatewayClient.makeRequest(dbopsRestageOrderPostRequest, authType, additionalParams, config.apiKey);
    };

    return apigClient;
};

export default apigClientFactory;
import apigClientFactory from '../chalice-javascript-sdk/apigClient';
// import * as config from '../Config/config';
import {secureStorage_getItem} from './secureStorage'

export const readAccess = async () => {
    var apigClient = apigClientFactory.newClient();
    var accesstoken = localStorage.getItem("accesstoken");

    var params = {
        customername: secureStorage_getItem("customername")
    }
    var additionalParams = {
        headers: {
            "Authorization": accesstoken
        }
    };


    return apigClient.vlauthTempCredentialsSTSCustomernameGet(params, '', additionalParams).then(async(result) => {
        var body = {
            accessid: result.data.AccessKeyId,
            secretkey: result.data.SecretKey,
            sessiontoken: result.data.SessionToken,
            bucketname: localStorage.getItem('bucketurl'),
            key: "AccessConfig/access.json"
        };

        return apigClient.awsopsPreSignedS3URLPut('', body, additionalParams).then(response => {

            let access = []
            let result = JSON.parse(response.data.content)
            Object.keys(result).forEach(key=>{
                if (typeof result[key] === 'object' && result[key].includes(secureStorage_getItem("customername"))){
                    // console.log(key)
                    access.push(key)
                }else if (typeof result[key] === 'boolean') {
                    if(result[key]){
                        access.push(key)
                    }
                }
            })
            return access

        }).catch(error => {
            return []
        });


    }).catch(error => {
        return []
    });


}
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from 'react';
import { createRoot } from "react-dom/client";
import {BrowserRouter as Router} from 'react-router-dom';
import {createStore, compose, combineReducers, applyMiddleware} from 'redux';
import {Provider} from 'react-redux';
import thunk from 'redux-thunk';
import './index.css';
import App from './App';
import registerServiceWorker from './registerServiceWorker';
import message_reducer from './store/reducers/message_reducer';
import workflow_reducer from './store/reducers/workflow_reducer';
import access_reducer from './store/reducers/access_reducer';
import workflow_activity_reducer from './store/reducers/workflow_activity_reducer';


const composeEnhancers = compose;

const rootReducer = combineReducers({
    messageReducer: message_reducer,
    workflowReducer: workflow_reducer,
    accessReducer: access_reducer,
    worflowActivityReducer: workflow_activity_reducer
})

const store = createStore(rootReducer,  composeEnhancers(applyMiddleware(thunk)))

const app = (
    <Provider store={store}>
        <Router>
            <App />
        </Router>
    </Provider>
)

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

root.render(app);

registerServiceWorker();

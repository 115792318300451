import SecureStorage from 'secure-web-storage';
import { resetStorage } from './resetStorage';

var CryptoJS = require("crypto-js");
 
var SECRET_KEY = 'virtualLogisticsVLOMNI';

const secureStorage = new SecureStorage(localStorage, {
    hash: function hash(key) {
        key = CryptoJS.SHA256(key, SECRET_KEY);
 
        return key.toString();
    },
    encrypt: function encrypt(data) {
        data = CryptoJS.AES.encrypt(data, SECRET_KEY);
 
        data = data.toString();
 
        return data;
    },
    decrypt: function decrypt(data) {
        data = CryptoJS.AES.decrypt(data, SECRET_KEY);
 
        data = data.toString(CryptoJS.enc.Utf8);
 
        return data;
    }
});

export const secureStorage_setItem = function(key, data){
    secureStorage.setItem(key, {data:data})
}

export const secureStorage_getItem = function(key){
    try{
        let result = secureStorage.getItem(key)
        if (result){
            return result.data
        }
        return ''

    }catch(e){
        resetStorage();
        window.location.replace('/login')
    }

}

export const secureStorage_removeItem = function(key){
    secureStorage.removeItem(key)
}